<template>
  <NbButton type="button" :variant="variant" @click.stop="downloadBoletoGNRE">
    <div :class="['d-flex align-items-center gap-1 relative', { loading }]">
      <NbSpinner v-if="loading" class="loading-spinner" />
      <span>
        <slot>
          {{ $t("download") }}
        </slot>
      </span>
    </div>
  </NbButton>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbSpinner from "@/components/loadings/NbSpinner.vue";
import FiscalPaymentService from "@/services/FiscalPaymentService";

const fiscalPaymentService = new FiscalPaymentService();

export default {
  props: {
    fiscalPaymentId: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      default: "tertiary",
    },
  },
  components: {
    NbButton,
    NbSpinner,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async downloadBoletoGNRE() {
      try {
        this.loading = true;
        const { data } = await fiscalPaymentService.getLabel(
          this.fiscalPaymentId
        );
        if (data?.data?.download_url) {
          window.open(data.data.download_url);
        }
      } catch (error) {
        this.$helpers.toast(error?.response?.data?.messages?.[0], "warning");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  &-spinner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  span {
    color: transparent;
  }
}
</style>
