export default {
  methods: {
    setQueryParams(queryParams = {}) {
      this.$router.replace({
        query: queryParams,
      });
    },
    setUrlSearchParam(key, value = undefined) {
      if (this.$route.query?.[key] != value) {
        this.setQueryParams({ [key]: value });
      }
    },
  },
};
