import { render, staticRenderFns } from "./ModalFooter.vue?vue&type=template&id=7af036ca&scoped=true&"
import script from "./ModalFooter.vue?vue&type=script&setup=true&lang=ts&"
export * from "./ModalFooter.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./ModalFooter.vue?vue&type=style&index=0&id=7af036ca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7af036ca",
  null
  
)

export default component.exports