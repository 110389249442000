<template>
  <div>
    <NbPageTitle :title="$t('fiscalPaymentsPage.title')" />

    <Modal
      v-model="showFiscalPayment"
      size="lg"
      @close="setUrlSearchParam('id')"
    >
      <FiscalPayment />
    </Modal>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" />

      <NbTablev2
        tableOf="fiscalPaymentsTable"
        namespace="fiscal_payments"
        ref="fiscalPaymentsTableRef"
        :clickable="true"
        :allFields="tableFields"
        :fields="fields"
        :tabs="true"
        :optionsWidth="260"
        :filterOptions="tableFilters"
        @reloadFields="fields = $event"
        @total="totalFiscalPayments = $event"
        selectable
        :buttonOptions="tableActions"
        :selectedItems.sync="selectedFiscalPaymentIds"
        @optionTook="optionTook($event)"
      >
        <template #cell(reference_number)="data">
          <NbButton
            variant="tertiary"
            @click="showFiscalPaymentDetails(data.item.id)"
          >
            {{ data.item.reference_number }}
          </NbButton>
        </template>
        <template #cell(boleto_gnre)="data">
          <DownloadGNRE :fiscal-payment-id="data.item.id" />
        </template>
        <template #cell(status)="data">
          <FiscalPaymentStatus :status="data.item.status" />
        </template>
        <template #cell(payment_type)="data">
          <FiscalPaymentTypeBadge :payment="data.item.payment_type" />
        </template>

        <template #cell(value)="data">
          {{ data.item.value | currencyBRL }}
        </template>

        <template #cell(payment_date)="data">
          {{ data.item.payment_date | datetime }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | datetime }}
        </template>

        <template #cell(dir_number)="data">
          {{ data.item.fiscal_parcel?.dir_number }}
        </template>
        <template #cell(fiscal_parcel.master_number)="data">
          {{ data.item?.fiscal_parcel?.master_number || "-" }}
        </template>
        <template #cell(customer_name)="data">
          {{ data.item.fiscal_parcel?.customer_name }}
        </template>
        <template #cell(customer_cpf)="data">
          {{ data.item.fiscal_parcel?.customer_cpf }}
        </template>
        <template #cell(customer_cep)="data">
          {{ data.item.fiscal_parcel?.customer_cep }}
        </template>
        <template #cell(tracking_number)="data">
          {{ data.item.fiscal_parcel?.tracking_number }}
        </template>
        <template #cell(products_description)="data">
          <NbButton
            :id="`popover-target-${data.item.id}`"
            class="truncate link-1"
            variant="tertiary"
            style="width: 200px"
          >
            {{ data.item?.fiscal_parcel?.products_description || "-" }}
          </NbButton>
          <b-popover
            :target="`popover-target-${data.item.id}`"
            triggers="hover"
            placement="top"
          >
            <template #title>
              {{ $t("fiscalPaymentsPage.fields.products_description") }}
            </template>
            <div class="grid gap-4">
              <span
                v-for="(
                  item, index
                ) in data.item?.fiscal_parcel?.products_description?.split(',')"
                :key="index"
              >
                {{ item }}
              </span>
            </div>
          </b-popover>
        </template>
        <template #cell(messages)="data">
          <NbButton
            v-show="data.item.messages"
            :id="`messages-${data.item.id}`"
            class="truncate link-1 text-left"
            variant="tertiary"
            style="width: 200px"
          >
            {{ data.item.messages }}
          </NbButton>
          <span v-if="!data.item.messages">-</span>
          <b-popover
            :target="`messages-${data.item.id}`"
            triggers="hover"
            placement="top"
          >
            <template #title>
              {{ $t("fiscalPaymentsPage.fields.messages") }}
            </template>
            <div class="grid gap-4">
              <span
                v-for="(item, index) in data.item.messages?.split(',')"
                :key="index"
              >
                {{ item }}
              </span>
            </div>
          </b-popover>
        </template>
        <template #cell(customer_state)="data">
          {{ data.item.fiscal_parcel?.customer_state }}
        </template>
        <template #cell(dir_date)="data">
          {{ data.item.fiscal_parcel?.dir_date | formatDate }}
        </template>
      </NbTablev2>
    </section>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import FiscalPaymentStatus from "./components/FiscalPaymentStatus.vue";
import FiscalPayment from "./components/FiscalPayment.vue";
import DownloadGNRE from "./components/DownloadGNRE.vue";
import FiscalPaymentTypeBadge from "./components/FiscalPaymentTypeBadge.vue";
import Modal from "@/components/modal/Modal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import queryParamsMixin from "@/mixins/query-params-mixin";
import moment from "moment";
import DownloadsService from "@/services/DownloadsService";

const downloadsService = new DownloadsService();

const currencyFormat = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export default {
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    NbTablev2,
    NbTabs,
    FiscalPaymentStatus,
    FiscalPayment,
    Modal,
    NbButton,
    DownloadGNRE,
    FiscalPaymentTypeBadge,
  },
  filters: {
    datetime(value) {
      return (value && moment(value).format("DD/MM/YYYY HH:mm:ss")) || "-";
    },
    formatDate(value) {
      return (value && moment(value).format("DD/MM/YYYY")) || "-";
    },
    currencyBRL(value) {
      return value && currencyFormat.format(Number(value));
    },
  },
  data() {
    return {
      currentTab: "all",
      totalFiscalPayments: 0,
      showFiscalPayment: false,
      fields: [],
      selectedFiscalPaymentIds: [],
    };
  },
  computed: {
    tableActions() {
      return [
        {
          text: this.$t("download"),
          value: "download",
        },
        {
          text: this.$t("fiscalPaymentsPage.downloadBoletosGNRE"),
          value: "downloadBoletosGNRE",
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalFiscalPayments,
        },
      ];
    },
    tableFields() {
      return [
        {
          key: "reference_number",
          label: this.$t("fiscalPaymentsPage.fields.reference_number"),
        },
        { key: "barcode", label: this.$t("fiscalPaymentsPage.fields.barcode") },
        {
          key: "authentication",
          label: this.$t("fiscalPaymentsPage.fields.authentication"),
        },
        {
          key: "boleto_gnre",
          label: this.$t("fiscalPaymentsPage.fields.boletoGNRE"),
        },
        { key: "status", label: this.$t("fiscalPaymentsPage.fields.status") },
        {
          key: "payment_type",
          label: this.$t("fiscalPaymentsPage.fields.payment_type"),
        },
        { key: "value", label: this.$t("fiscalPaymentsPage.fields.value") },
        {
          key: "fiscal_parcel_id",
          label: this.$t("fiscalPaymentsPage.fields.fiscal_parcel_id"),
        },
        {
          key: "courrier_name",
          label: this.$t("fiscalPaymentsPage.fields.courrierName"),
        },
        {
          key: "courrier_cnpj",
          label: this.$t("fiscalPaymentsPage.fields.courrierCNPJ"),
        },
        {
          key: "payment_date",
          label: this.$t("fiscalPaymentsPage.fields.payment_date"),
        },
        {
          key: "created_at",
          label: this.$t("fiscalPaymentsPage.fields.created_at"),
        },
        {
          key: "dir_number",
          label: this.$t("fiscalPaymentsPage.fields.dir_number"),
        },
        {
          key: "fiscal_parcel.master_number",
          label: this.$t("fiscalPaymentsPage.fields.masterNumber"),
        },
        {
          key: "customer_name",
          label: this.$t("fiscalPaymentsPage.fields.customer_name"),
        },
        {
          key: "customer_cpf",
          label: this.$t("fiscalPaymentsPage.fields.customer_cpf"),
        },
        {
          key: "customer_cep",
          label: this.$t("fiscalPaymentsPage.fields.customer_cep"),
        },
        {
          key: "tracking_number",
          label: this.$t("fiscalPaymentsPage.fields.tracking_number"),
        },
        {
          key: "products_description",
          label: this.$t("fiscalPaymentsPage.fields.products_description"),
        },
        {
          key: "messages",
          label: this.$t("fiscalPaymentsPage.fields.messages"),
        },
        {
          key: "customer_state",
          label: this.$t("fiscalPaymentsPage.fields.customer_state"),
        },
        {
          key: "dir_date",
          label: this.$t("fiscalPaymentsPage.fields.dir_date"),
        },
      ];
    },
    paymentTypeOptions() {
      return [
        "icms",
        "darf",
        "fine689",
        "fine702",
        "fine703",
        "fine711",
        "fine715",
        "fine725",
        "fine728",
      ];
    },
    tableFilters() {
      return [
        {
          key: "reference_number",
          label: this.$t("fiscalPaymentsPage.fields.reference_number"),
        },
        {
          key: "barcode",
          label: this.$t("fiscalPaymentsPage.fields.barcode"),
        },
        {
          key: "authentication",
          label: this.$t("fiscalPaymentsPage.fields.authentication"),
        },
        {
          key: "status",
          label: this.$t("fiscalPaymentsPage.fields.status"),
          type: "select",
          options: [
            {
              value: "processing",
              text: this.$t("fiscalPaymentsPage.status.processing"),
            },
            {
              value: "processed",
              text: this.$t("fiscalPaymentsPage.status.processed"),
            },
            {
              value: "error",
              text: this.$t("fiscalPaymentsPage.status.error"),
            },
          ],
        },
        {
          key: "payment_type",
          label: this.$t("fiscalPaymentsPage.fields.payment_type"),
          options: this.paymentTypeOptions.map((value) => ({
            text: value,
            value,
          })),
          type: "select",
        },
        {
          key: "payment_date",
          label: this.$t("fiscalPaymentsPage.fields.paymentDate"),
          type: "dates",
        },
        {
          key: "courrier_name",
          label: this.$t("fiscalPaymentsPage.fields.courrierName"),
        },
        {
          key: "courrier_cnpj",
          label: this.$t("fiscalPaymentsPage.fields.courrierCNPJ"),
        },
        {
          key: "created_at",
          label: this.$t("registrationDate"),
          type: "dates",
        },
        {
          key: "fiscal_parcel_id",
          label: this.$t("fiscalPaymentsPage.fields.fiscal_parcel_id"),
          type: "number",
        },
        {
          key: "start_value",
          label: this.$t("fiscalPaymentsPage.fields.value"),
          type: "money",
          startKey: "start_value",
          endKey: "end_value",
        },
        {
          key: "fiscal_parcels.dir_number",
          label: this.$t("fiscalPaymentsPage.fields.dir_number"),
        },
        {
          key: "fiscal_parcels.master_number",
          label: this.$t("fiscalPaymentsPage.fields.masterNumber"),
        },
        {
          key: "fiscal_parcels.start_dir_date",
          label: this.$t("fiscalPaymentsPage.fields.dir_date"),
          type: "dates",
          startDateKey: "fiscal_parcels.start_dir_date",
          endDateKey: "fiscal_parcels.end_dir_date",
        },
        {
          key: "fiscal_parcels.customer_name",
          label: this.$t("fiscalPaymentsPage.fields.customer_name"),
        },
        {
          key: "fiscal_parcels.customer_cpf",
          label: this.$t("fiscalPaymentsPage.fields.customer_cpf"),
        },
        {
          key: "fiscal_parcels.customer_cep",
          label: this.$t("fiscalPaymentsPage.fields.customer_cep"),
        },
        {
          key: "fiscal_parcels.tracking_number",
          label: this.$t("fiscalPaymentsPage.fields.tracking_number"),
        },
        {
          key: "fiscal_parcels.products_description",
          label: this.$t("fiscalPaymentsPage.fields.products_description"),
        },
        {
          key: "fiscal_parcels.customer_state",
          label: this.$t("fiscalPaymentsPage.fields.customer_state"),
        },
      ];
    },
  },
  methods: {
    optionTook(event) {
      this[event]();
    },
    async processDownload(dataType, docType) {
      try {
        await downloadsService.loadProcessing({
          data_type: dataType,
          reference_ids: this.selectedFiscalPaymentIds,
          doc_type: docType,
        });
        this.$router.push("/archives");
      } catch {
        this.$bvToast.toast(this.$t("errorMessages.genericError"), {
          variant: "danger",
        });
      }
    },
    downloadBoletosGNRE() {
      this.processDownload("boletos_zip", "zip");
    },
    download() {
      this.processDownload("fiscal_payments", "csv");
    },
    showFiscalPaymentDetails(id) {
      this.setUrlSearchParam("id", id);
      this.showFiscalPayment = true;
    },
  },
  created() {
    const { id } = this.$route.query;
    if (!isNaN(Number(id))) {
      this.showFiscalPaymentDetails(id);
    }
  },
};
</script>

<style lang="scss" scoped></style>
