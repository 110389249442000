import HttpService from "./HttpService";

export default class DownloadService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get downloads
   *
   * @param data
   * @returns {Promise}
   */
  getDownloads(data = {}) {
    return this.httpService.get("/v1/downloaded_files/", {
      params: data,
    });
  }

  /**
   * Set download
   *
   * @param data
   * @returns {Promise}
   */
  loadProcessing(data = {}) {
    return this.httpService.post("/v1/downloaded_files/process_files", data);
  }

  /**
   * Get additional_data
   *
   * @param id
   * @returns {Promise}
   */
  getAdditionaldata(id = null) {
    return this.httpService.get(
      "/v1/downloaded_files/" + id + "/get_additional_data",
      {
        params: id,
      }
    );
  }

  /**
   * Generate link
   *
   * @param {*} id
   * @returns {Promise}
   */
  generateLink(id = null) {
    return this.httpService.put("/v1/downloaded_files/" + id);
  }
}
